<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700"
      @click:outside="closeUnitProductModal()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          depressed
          v-bind="attrs"
          v-on="on">
          <v-icon>
            mdi-plus-circle
          </v-icon>
          <span>
            {{ $t('product.btn.addUnit') }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closeUnitProductModal()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('product.label.createUnit')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <v-row no-gutters>
              <v-col
                cols="3">
                <text-field-label
                  :label="$t('product.textField.unitName.label')" />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.name"
                  :placeholder="$t('product.textField.unitName.placeholder')"
                  :rules="nameRules"
                  dense
                  outlined
                  required />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pt-2">
                <div class="d-flex">
                  <text-field-label
                    style="width: 180px;"
                    :label="$t('product.textField.startUnitQuantity.label')" />
                  <v-text-field
                    v-model="form.ofBaseUnit"
                    :placeholder="$t('product.textField.startUnitQuantity.placeholder')"
                    :rules="ofBaseUnitRules"
                    class="ml-10"
                    dense
                    outlined
                    required />
                </div>
              </v-col>
              <v-col class="pt-2 pl-4">
                <div class="d-flex">
                  <text-field-label
                    style="width: 130px;"
                    :label="$t('product.textField.startUnitOfUnit.label')" />
                  <v-text-field
                    v-model="form.abbreviation"
                    :placeholder="$t('product.textField.startUnitOfUnit.placeholder')"
                    :rules="abbreviationRules"
                    class="ml-6"
                    dense
                    outlined
                    required />
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex justify-end mt-4">
            <v-btn
              color="primary"
              text
              @click="closeUnitProductModal()">
              {{ $t('product.btn.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              rounded
              depressed
              @click="submit()">
              {{ $t('product.btn.confirm') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CREATE_UNIT } from '@/resources/graphql'

export default {
  name: 'UnitProductModal',
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      form: {
        name: '',
        abbreviation: '',
        ofBaseUnit: null
      },
      nameRules: [
        (v) => !!v || this.$t('product.textField.unitName.validate.required')
      ],
      ofBaseUnitRules: [
        (v) => !!v || this.$t('product.textField.startUnitQuantity.validate.required')
      ],
      abbreviationRules: [
        (v) => !!v || this.$t('product.textField.startUnitOfUnit.validate.required')
      ]
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    resetForm () {
      this.form = {
        name: '',
        abbreviation: '',
        ofBaseUnit: null
      }
    },
    closeUnitProductModal () {
      this.dialog = false
      this.resetForm()
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_UNIT,
          variables: {
            input: {
              name: this.form.name,
              abbreviation: this.form.abbreviation,
              ofBaseUnit: Number(this.form.ofBaseUnit)
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.$emit('submit')
          this.state = 'success'
          this.dialog = false
          this.resetForm()
          this.$refs.form.resetValidation()
        }
      }
    }
  }
}
</script>

<style>

</style>
