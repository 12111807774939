var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("product.header"),
              "prepend-icon": "$productHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", [
            _vm._v(" " + _vm._s(_vm.$t("product.tab.productMagnage")) + " ")
          ]),
          _vm.permission.settingUnit
            ? _c("v-tab", [
                _vm._v(" " + _vm._s(_vm.$t("product.tab.unitProduct")) + " ")
              ])
            : _vm._e(),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("select-form", {
                                staticClass: "product-input-style",
                                attrs: {
                                  text: _vm.$t(
                                    "product.productManage.productSortBy"
                                  ),
                                  state: _vm.state.productSortBy,
                                  items: _vm.productSort,
                                  "item-text": "description",
                                  "item-value": "name"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.submit("productSortBy")
                                  }
                                },
                                model: {
                                  value: _vm.setting.productSortBy,
                                  callback: function($$v) {
                                    _vm.$set(_vm.setting, "productSortBy", $$v)
                                  },
                                  expression: "setting.productSortBy"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("select-form", {
                                staticClass: "product-input-style",
                                attrs: {
                                  text: _vm.$t(
                                    "product.productManage.countProduct"
                                  ),
                                  state: _vm.state.quantityDiscountMethod,
                                  items: _vm.quantityDiscount,
                                  "item-text": "text",
                                  "item-value": "value"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.submit("quantityDiscountMethod")
                                  }
                                },
                                model: {
                                  value: _vm.setting.quantityDiscountMethod,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.setting,
                                      "quantityDiscountMethod",
                                      $$v
                                    )
                                  },
                                  expression: "setting.quantityDiscountMethod"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.permission.settingUnit
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("unit-product-data-table", {
                            ref: "UnitDatatable"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.tab === 1
            ? _c(
                "div",
                { staticClass: "add-btn" },
                [
                  _c("unit-product-create", {
                    on: {
                      submit: function($event) {
                        return _vm.refetchUnitDatatable()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }