<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('product.header')"
        prepend-icon="$productHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-tabs
      v-model="tab"
      class="app-tab"
      show-arrows>
      <v-tab>
        {{ $t('product.tab.productMagnage') }}
      </v-tab>
      <v-tab v-if="permission.settingUnit">
        {{ $t('product.tab.unitProduct') }}
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="7">
                <select-form
                  v-model="setting.productSortBy"
                  :text="$t('product.productManage.productSortBy')"
                  :state="state.productSortBy"
                  :items="productSort"
                  item-text="description"
                  item-value="name"
                  class="product-input-style"
                  @input="submit('productSortBy')">
                </select-form>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              no-gutters>
              <v-col cols="7">
                <select-form
                  v-model="setting.quantityDiscountMethod"
                  :text="$t('product.productManage.countProduct')"
                  :state="state.quantityDiscountMethod"
                  :items="quantityDiscount"
                  item-text="text"
                  item-value="value"
                  class="product-input-style"
                  @input="submit('quantityDiscountMethod')">
                </select-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="permission.settingUnit">
        <v-card flat>
          <v-card-text>
            <unit-product-data-table
              ref="UnitDatatable">
            </unit-product-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <div
        v-if="tab === 1"
        class="add-btn">
        <unit-product-create
          @submit="refetchUnitDatatable()">
        </unit-product-create>
      </div>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UnitProductDataTable from '@/views/ShopSetting/Pages/Product/components/Unit/UnitProductDataTable'
import UnitProductCreate from '@/views/ShopSetting/Pages/Product/components/Unit/UnitProductCreate'
import { GET_SETTING_PRODUCT } from '@/resources/graphql/query/Setting'
import { GET_PRODUCTSORTBY_ENUM } from '@/resources/graphql/query/Enum'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Product',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_PRODUCT
    }),
    productSort: () => ({
      query: GET_PRODUCTSORTBY_ENUM,
      result ({ data: { __type } }) {
        this.productSort = __type.enumValues
      },
      update: (data) => data.__type
    })
  },
  components: {
    UnitProductDataTable,
    UnitProductCreate
  },
  data () {
    return {
      setting: {},
      tab: 0,
      productSort: [],
      quantityDiscount: [
        {
          text: 'จากสินค้าเดียวกัน',
          value: 'within_item'
        },
        {
          text: 'จากสินค้าหลักเดียวกัน',
          value: 'within_sku'
        },
        {
          text: 'จากทั้งออเดอร์',
          value: 'within_order'
        }
      ],
      state: {
        productSortBy: 'ready',
        quantityDiscountMethod: 'ready'
      }
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    })
  },
  methods: {
    refetchUnitDatatable () {
      this.$refs.UnitDatatable.refetch()
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>
  .product-input-style .v-select {
    max-width: 200px;
  }
</style>

<style scoped>
  .add-btn {
    position: absolute;
    right: 0;
  }
</style>
