var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _vm.units.nodes && _vm.units.nodes.length
            ? _c("v-data-table", {
                staticClass: "app-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.units.nodes,
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header.name",
                      fn: function(ref) {
                        var header = ref.header
                        return [_vm._v(" " + _vm._s(header.text) + " ")]
                      }
                    },
                    {
                      key: "header.abbreviation",
                      fn: function(ref) {
                        var header = ref.header
                        return [_vm._v(" " + _vm._s(header.text) + " ")]
                      }
                    },
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.enableEdit && item.id === _vm.editId
                            ? _c("v-text-field", {
                                staticStyle: { "max-width": "50%" },
                                attrs: {
                                  value: item.name,
                                  rules: _vm.nameRules,
                                  dense: "",
                                  outlined: "",
                                  required: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.submit("name", $event)
                                  }
                                }
                              })
                            : _c("span", [
                                _vm._v(" " + _vm._s(item.name) + " ")
                              ])
                        ]
                      }
                    },
                    {
                      key: "item.abbreviation",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _vm.enableEdit && item.id === _vm.editId
                                ? _c("v-text-field", {
                                    staticClass: "mr-2",
                                    staticStyle: { "max-width": "50%" },
                                    attrs: {
                                      value: item.ofBaseUnit,
                                      rules: _vm.ofBaseUnitRules,
                                      dense: "",
                                      outlined: "",
                                      required: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.submit("ofBaseUnit", $event)
                                      }
                                    }
                                  })
                                : _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(" " + _vm._s(item.ofBaseUnit) + " ")
                                  ]),
                              _c(
                                "span",
                                {
                                  class: {
                                    "mt-2":
                                      _vm.enableEdit && item.id === _vm.editId
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.abbreviation) + " ")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.edit",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.enableEdit && item.id === _vm.editId
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editUnit(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("product.btn.cancel")) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editUnit(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" mdi-square-edit-outline ")]
                                  )
                                ],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "item.delete",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("unit-product-remove", {
                            attrs: { "unit-id": item.id },
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("p", { staticClass: "mt-4 mb-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("product.label.emptyUnit")) +
                                    " "
                                )
                              ]),
                              _c("unit-product-create", {
                                on: {
                                  submit: function($event) {
                                    return _vm.refetch()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-pagination", {
        attrs: { length: _vm.units.pagesCount, "total-visible": 7 },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }