<template>
  <div>
    <v-form ref="form">
      <v-data-table
        v-if="units.nodes && units.nodes.length"
        :headers="headers"
        :items="units.nodes"
        class="app-table"
        hide-default-footer>
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.abbreviation`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-text-field
            v-if="enableEdit && item.id === editId"
            :value="item.name"
            :rules="nameRules"
            style="max-width: 50%;"
            dense
            outlined
            required
            @input="submit('name', $event)" />
          <span v-else>
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.abbreviation`]="{ item }">
          <div class="d-flex">
            <v-text-field
              v-if="enableEdit && item.id === editId"
              :value="item.ofBaseUnit"
              :rules="ofBaseUnitRules"
              class="mr-2"
              style="max-width: 50%;"
              dense
              outlined
              required
              @input="submit('ofBaseUnit', $event)" />
            <span
              v-else
              class="mr-2">
              {{ item.ofBaseUnit }}
            </span>
            <span :class="{ 'mt-2' : enableEdit && item.id === editId }">
              {{ item.abbreviation }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn
            v-if="enableEdit && item.id === editId"
            color="primary"
            class="mb-5"
            text
            @click="editUnit(item)">
            {{ $t('product.btn.cancel') }}
          </v-btn>
          <v-btn
            v-else
            icon
            @click="editUnit(item)">
            <v-icon color="primary">
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <unit-product-remove
            :unit-id="item.id"
            @submit="refetch()">
          </unit-product-remove>
        </template>
        <template v-slot:[`no-data`]>
          <div class="text-center">
            <p class="mt-4 mb-4">
              {{ $t('product.label.emptyUnit') }}
            </p>
            <unit-product-create @submit="refetch()" />
          </div>
        </template>
      </v-data-table>
    </v-form>
    <v-pagination
      v-model="page"
      :length="units.pagesCount"
      :total-visible="7">
    </v-pagination>
  </div>
</template>

<script>
import UnitProductCreate from '@/views/ShopSetting/Pages/Product/components/Unit/UnitProductCreate'
import UnitProductRemove from '@/views/ShopSetting/Pages/Product/components/Unit/UnitProductRemove'
import { GET_UNITS } from '@/resources/graphql/query/Units'
import { UPDATE_UNIT } from '@/resources/graphql'

export default {
  name: 'ProductDataTable',
  apollo: {
    units: () => ({
      fetchPolicy: 'no-cache',
      query: GET_UNITS,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage
        }
      },
      result ({ data: { units } }) {
        if (this.page > units.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    UnitProductCreate,
    UnitProductRemove
  },
  data () {
    return {
      units: [],
      page: 1,
      perPage: 5,
      enableEdit: false,
      editId: null,
      pagination: true,
      headers: [
        {
          text: this.$t('product.table.header.unitName'),
          align: 'start',
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('product.table.header.unitQuantity'),
          align: 'start',
          value: 'abbreviation',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'edit',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          value: 'delete',
          sortable: false
        }
      ],
      nameRules: [
        (v) => !!v || this.$t('product.textField.unitName.validate.required')
      ],
      ofBaseUnitRules: [
        (v) => !!v || this.$t('product.textField.startUnitQuantity.validate.required')
      ]
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.units.refetch()
    },
    editUnit (item) {
      if (this.$refs.form.validate()) {
        this.enableEdit = !this.enableEdit
        this.editId = item.id
      }
    },
    async submit (field, val) {
      if (this.$refs.form.validate()) {
        let value = val
        if (field === 'ofBaseUnit') {
          value = Number(val)
        }
        const res = await this.$apollo.mutate({
          mutation: UPDATE_UNIT,
          variables: {
            input: {
              id: this.editId,
              [field]: value
            }
          }
        }).catch(() => {})
        if (res) {
          this.refetch()
        }
      }
    }
  }
}
</script>

<style>
  .app-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
    padding-left: 2rem;
  }
</style>
