import gql from 'graphql-tag'

export const GET_UNITS = gql`
  query ($page: Int, $perPage: Int) {
    units(page: $page, perPage: $perPage) {
      nodes {
        id
        name
        ofBaseUnit
        abbreviation
      }
      pagesCount
      nodesCount
    }
  }
`

export default {
  GET_UNITS
}
