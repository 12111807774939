var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          on: {
            "click:outside": function($event) {
              return _vm.closeUnitProductModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            rounded: "",
                            depressed: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [_vm._v(" mdi-plus-circle ")]),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("product.btn.addUnit")) + " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c(
                    "div",
                    { staticClass: "close-popup" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeUnitProductModal()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("title-label", {
                    attrs: { label: _vm.$t("product.label.createUnit") }
                  }),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "mt-3",
                      attrs: { readonly: _vm.loading, "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "product.textField.unitName.label"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "product.textField.unitName.placeholder"
                                  ),
                                  rules: _vm.nameRules,
                                  dense: "",
                                  outlined: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { staticClass: "pt-2" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("text-field-label", {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    label: _vm.$t(
                                      "product.textField.startUnitQuantity.label"
                                    )
                                  }
                                }),
                                _c("v-text-field", {
                                  staticClass: "ml-10",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "product.textField.startUnitQuantity.placeholder"
                                    ),
                                    rules: _vm.ofBaseUnitRules,
                                    dense: "",
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.ofBaseUnit,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "ofBaseUnit", $$v)
                                    },
                                    expression: "form.ofBaseUnit"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("v-col", { staticClass: "pt-2 pl-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("text-field-label", {
                                  staticStyle: { width: "130px" },
                                  attrs: {
                                    label: _vm.$t(
                                      "product.textField.startUnitOfUnit.label"
                                    )
                                  }
                                }),
                                _c("v-text-field", {
                                  staticClass: "ml-6",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "product.textField.startUnitOfUnit.placeholder"
                                    ),
                                    rules: _vm.abbreviationRules,
                                    dense: "",
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.abbreviation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "abbreviation", $$v)
                                    },
                                    expression: "form.abbreviation"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end mt-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeUnitProductModal()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("product.btn.cancel")) + " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            rounded: "",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.submit()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("product.btn.confirm")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }